import React from "react"
import {graphql} from "gatsby"
import parse from "html-react-parser"
import Seo from "../component/seo"
import Helmet from 'react-helmet'
// import {Link} from 'gatsby'
import favicon from '../images/favicon.ico'
import Layout from '../component/layout'


const Homepage=({data})=>{
    return(
    <React.Fragment>
      <Layout> 
      <Helmet>
        <link rel="icon" href={favicon} /> 
        </Helmet> 
        <Seo title="homepage"/> 
        {parse(data.allWpPage.edges[0].node.content)} 
      </Layout>  
      </React.Fragment>
    )
}
// in locol use this "allWpPage(filter: {title: {eq: "Homepage"}}) { " 
export const Pagequery = graphql`query{
    allWpPage(filter: {title: {eq: "Home"}}) {
      edges {
        node {
          id
          title
          content
        }
      }
    }
  }
`
export default Homepage